<template>
  <div id="wrapper">
        <navbar id="nav" class="no-print animate__animated animate__fadeInLeft"  style="display: none;"/>
        <div class="d-flex flex-column animate__animated animate__fadeInRight" id="content-wrapper" style="display: none !important;">
            <div id="content">
                <header-btn class="no-print"/>
                <div class="container-fluid text-dark">
                    <div class="w-100 text-center mt-15 pt-15" v-if="loading">
                        <v-progress-circular indeterminate color="primary" size="50"></v-progress-circular>
                    </div>
                    <table v-else-if="user.role == 'A' || user.role == 'E' || (user.role == 'K' && !is_print)" class="table table-bordered table-striped text-center text-dark" style="page-break-inside: always; font-size: 12pt !important;">
                        <thead>
                            <th> Total Salary </th>
                            <th> Total Overtime Hours </th>
                            <th> Total Food </th>
                            <th> Total Transport </th>
                            <th> Total Cabina </th>
                            <th> Total Expense </th>
                            <th> Total Fine </th>
                            <th> Total Loan </th>
                            <th> Total Accomodation </th>
                            <th> Total Salary per Month </th>
                            <th> Total Other Minus </th>
                            <th> Sub Total </th>
                        </thead>
                        <tbody>
                            <template v-for="staff in staffs" >
                                <tr :key="'n' + staff.st_id">
                                    <td colspan="12" class="text-left ps-4"> {{ staff.staff_name }} </td>
                                </tr>
                                <tr :key="'d' + staff.st_id">
                                    <td>
                                        <span> {{ (getTotalsByStaff(staff.st_id).total_salary_dollar).toLocaleString() }} $ </span>
                                        <br><br>
                                        <span >  {{ (getTotalsByStaff(staff.st_id).total_salary_dinar).toLocaleString() }} </span>
                                    </td>
                                    <td> 
                                        <span> {{ (getTotalsByStaff(staff.st_id).total_overtime_dollar).toLocaleString() }} $ </span>
                                        <br><br>
                                        <span> {{ (getTotalsByStaff(staff.st_id).total_overtime_dinar).toLocaleString() }} </span>
                                    </td>
                                    <td> 
                                        <span> {{ (getTotalsByStaff(staff.st_id).total_food_dollar).toLocaleString() }} $ </span>
                                        <br><br>
                                        <span> {{ (getTotalsByStaff(staff.st_id).total_food_dinar).toLocaleString() }} </span>
                                    </td>
                                    <td> 
                                        <span> {{ (getTotalsByStaff(staff.st_id).total_transport_dollar).toLocaleString() }} $ </span>
                                        <br><br>
                                        <span> {{ (getTotalsByStaff(staff.st_id).total_transport_dinar).toLocaleString() }} </span>
                                    </td>
                                    <td> 
                                        <span> {{ (getTotalsByStaff(staff.st_id).total_cabina_dollar).toLocaleString() }} $ </span>
                                        <br><br>
                                        <span> {{ (getTotalsByStaff(staff.st_id).total_cabina_dinar).toLocaleString() }} </span>
                                    </td>
                                    <td> 
                                        <span> {{ (getTotalsByStaff(staff.st_id).total_expense_dollar).toLocaleString() }} $ </span>
                                        <br><br>
                                        <span> {{ (getTotalsByStaff(staff.st_id).total_expense_dinar).toLocaleString() }} </span>
                                    </td>
                                    <td> 
                                        <span> {{ (getTotalsByStaff(staff.st_id).total_fine_dollar).toLocaleString() }} $ </span>
                                        <br><br>
                                        <span> {{ (getTotalsByStaff(staff.st_id).total_fine_dinar).toLocaleString() }} </span>
                                    </td>
                                    <td> 
                                        <span> {{ (getTotalsByStaff(staff.st_id).total_loan_dollar).toLocaleString() }} $ </span>
                                        <br><br>
                                        <span> {{ (getTotalsByStaff(staff.st_id).total_loan_dinar).toLocaleString() }} </span>
                                    </td>
                                    <td> 
                                        <span> {{ (getTotalsByStaff(staff.st_id).total_accomodation_dollar).toLocaleString() }} $ </span>
                                        <br><br>
                                        <span> {{ (getTotalsByStaff(staff.st_id).total_accomodation_dinar).toLocaleString() }} </span>
                                    </td>
                                    <td> 
                                        <span> {{ (getTotalsByStaff(staff.st_id).total_salary_per_month_dollar).toLocaleString() }} $ </span>
                                        <br><br>
                                        <span> {{ (getTotalsByStaff(staff.st_id).total_salary_per_month_dinar).toLocaleString() }} </span>
                                    </td>
                                    <td> 
                                        <span> {{ (getTotalsByStaff(staff.st_id).total_other_minus_dollar).toLocaleString() }} $ </span>
                                        <br><br>
                                        <span> {{ (getTotalsByStaff(staff.st_id).total_other_minus_dinar).toLocaleString() }} </span>
                                    </td>
                                    <td> 
                                        <span> {{ (getTotalsByStaff(staff.st_id).sub_total_dollar_dollar).toLocaleString() }} $ </span>
                                        <br><br>
                                        <span> {{ (getTotalsByStaff(staff.st_id).sub_total_dinar_dinar).toLocaleString() }} </span>
                                    </td>
                                </tr>
                            </template>
                            <tr>
                                <td class="para">
                                    <span> {{ (totals.total_salary_dollar).toLocaleString() }} $ </span>
                                    <br><br>
                                    <span> {{ (totals.total_salary_dinar).toLocaleString() }} </span>
                                </td>
                                <td class="para">
                                    <span> {{ (totals.total_overtime_dollar).toLocaleString() }} $ </span>
                                    <br><br>
                                    <span> {{ (totals.total_overtime_dinar).toLocaleString() }} </span>
                                </td>
                                <td class="para">
                                    <span> {{ (totals.total_food_dollar).toLocaleString() }} $ </span>
                                    <br><br>
                                    <span> {{ (totals.total_food_dinar).toLocaleString() }} </span>
                                </td>
                                <td class="para">
                                    <span> {{ (totals.total_transport_dollar).toLocaleString() }} $ </span>
                                    <br><br>
                                    <span> {{ (totals.total_transport_dinar).toLocaleString() }} </span>
                                </td>
                                <td class="para">
                                    <span> {{ (totals.total_cabina_dollar).toLocaleString() }} $ </span>
                                    <br><br>
                                    <span> {{ (totals.total_cabina_dinar).toLocaleString() }} </span>
                                </td>
                                <td class="para">
                                    <span> {{ (totals.total_expense_dollar).toLocaleString() }} $ </span>
                                    <br><br>
                                    <span> {{ (totals.total_expense_dinar).toLocaleString() }} </span>
                                </td>
                                <td class="para">
                                    <span> {{ (totals.total_fine_dollar).toLocaleString() }} $ </span>
                                    <br><br>
                                    <span> {{ (totals.total_fine_dinar).toLocaleString() }} </span>
                                </td>
                                <td class="para">
                                    <span> {{ (totals.total_loan_dollar).toLocaleString() }} $ </span>
                                    <br><br>
                                    <span> {{ (totals.total_loan_dinar).toLocaleString() }} </span>
                                </td>
                                <td class="para">
                                    <span> {{ (totals.total_accomodation_dollar).toLocaleString() }} $ </span>
                                    <br><br>
                                    <span> {{ (totals.total_accomodation_dinar).toLocaleString() }} </span>
                                </td>
                                <td class="para">
                                    <span> {{ (totals.total_salary_per_month_dollar).toLocaleString() }} $ </span>
                                    <br><br>
                                    <span> {{ (totals.total_salary_per_month_dinar).toLocaleString() }} </span>
                                </td>
                                <td class="para">
                                    <span> {{ (totals.total_other_minus_dollar).toLocaleString() }} $ </span>
                                    <br><br>
                                    <span> {{ (totals.total_other_minus_dinar).toLocaleString() }} </span>
                                </td>
                                <td class="para">
                                    <span> {{ (totals.sub_total_dollar_dollar).toLocaleString() }} $ </span>
                                    <br><br>
                                    <span> {{ (totals.sub_total_dinar_dinar).toLocaleString() }} </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import auth from '../auth';
export default {
    name: 'total_salary_list',
    data() {
        return {
            list: [],
            zeros: [],
            dollar_price: 0,
            month: Number(this.$route.params.month),
            year: Number(this.$route.params.year),
            loading: true,
            staffs: [],
            is_print: false,
            user: {}
        }
    },
    beforeCreate(){
        auth("total_salary_list");
    },
    created() {
        this.$http.post("/getLoggedInfo").then(({data}) => {
            this.user.username = data.username;
            this.user.role = data.type;
            this.user.en_id = data.en_id || null;
        });
    },
    mounted() {
        this.dollar_price = Number(this.$route.params.dollar_price);
        this.$http.post("/staff/getData").then(({data}) => {
            this.staffs = data.filter(obj => obj.show_staff == '1').sort((a, b) => a.staff_sort_code - b.staff_sort_code)
            if(this.$route.params.special == 'normals') {
                this.staffs = this.staffs.filter(obj => obj.special_staff == 'false')
            } else {
                this.staffs = this.staffs.filter(obj => obj.special_staff == 'true')
            }
            this.$http.post('/employee/getSalaryListByMonthAndYearForTotal', {
                month: Number(this.$route.params.month),
                year: Number(this.$route.params.year),
                special: this.$route.params.special
            }).then(({data}) => {
                this.loading = false;
                this.list = data.salary_list;
                this.zeros = data.zeros;
                this.overWorkDayToOvertime();
            })
        });
        window.matchMedia('print').addListener(e => {
            if(e.matches) {
                this.is_print = true;
            } else {
                this.is_print = false;
            }
        });
    },
    methods: {
        fridays(year, month) {
            var day, counter, date;
            day = 1;
            counter = 0;
            date = new Date(year, month, day);
            
            while (date.getMonth() === month) {
                if (date.getDay() === 5) { // Sun=0, Mon=1, Tue=2, etc.
                    counter += 1;
                }
                day += 1;
                date = new Date(year, month, day);
            }
            return counter;
        },
        getDaysAndFridays(month, year) {
            const totalDays = new Date(year, month, 0).getDate();
            let fridays = 0;

            for (let day = 1; day <= totalDays; day++) {
                const currentDate = new Date(year, month - 1, day);
                if (currentDate.getDay() === 5) {
                    fridays++;
                }
            }

            return {
                days: totalDays,
                fridays: fridays
            };
        },
        overWorkDayToOvertime() {
            for(let i = 0; i < this.list.length; i++) {
                var count_present = this.list[i].count_present;
                // var d = new Date(this.year, this.month - 1);
                
                // const allDaysInMonth = new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();
                // const numberOfFridays = this.fridays(this.year, this.month - 1);
                const allDaysInMonth = this.getDaysAndFridays(this.month, this.year).days;
                const numberOfFridays = this.getDaysAndFridays(this.month, this.year).fridays;
                
                const days = allDaysInMonth - numberOfFridays;
                
                if(count_present > days) {
                    var overtimeDays = count_present - days;
                    this.list[i].total_hour += (overtimeDays * 8);
                    this.list[i].count_present = days;
                }
            }
        },
        getSuitableSalary(item) {
            // var d = new Date();
            // d.setMonth(this.month - 1);
            // d.setFullYear(this.year);
            // const allDaysInMonth = new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();
            // const numberOfFridays = this.fridays(this.year, this.month - 1);
            const allDaysInMonth = this.getDaysAndFridays(this.month, this.year).days;
            const numberOfFridays = this.getDaysAndFridays(this.month, this.year).fridays;
            const days = allDaysInMonth - numberOfFridays;
            if(item.salary_type == 'Monthly') {
                item.daily_salary = Number(item.monthly_salary / days).toFixed(2);
                return Number(Number(item.monthly_salary / days).toFixed(2));
            } else {
                return (item.daily_salary);
            }

        },
        getTotalsByStaff(st_id) {
            let totals_obj = {
                total_salary_dollar: this.list.filter(item => item.salary_type == 'Monthly').filter(obj => obj.st_id == st_id).filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + (this.getSuitableSalary(n) * n.count_present), 0),
                total_salary_dinar: this.list.filter(item => item.salary_type == 'Daily').filter(obj => obj.st_id == st_id).filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + (this.getSuitableSalary(n) * n.count_present), 0),

                total_overtime_dollar: this.list.filter(item => item.salary_type == 'Monthly').filter(obj => obj.st_id == st_id).filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number((((n.monthly_salary/26) / 8 ) * n.total_hour).toFixed(2)), 0),
                total_overtime_dinar: this.list.filter(item => item.salary_type == 'Daily').filter(obj => obj.st_id == st_id).filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(((n.daily_salary / 8 ) * n.total_hour).toFixed(2)), 0),

                total_food_dollar: this.list.filter(item => item.salary_type == 'Monthly').filter(obj => obj.st_id == st_id).filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(Number(n.food_money) + Number(n.total_food)), 0),
                total_food_dinar: this.list.filter(item => item.salary_type == 'Daily').filter(obj => obj.st_id == st_id).filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(Number(n.food_money) + Number(n.total_food)), 0),

                total_transport_dollar: this.list.filter(item => item.salary_type == 'Monthly').filter(obj => obj.st_id == st_id).filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(Number(n.transport_money) + Number(n.total_transport)), 0),
                total_transport_dinar: this.list.filter(item => item.salary_type == 'Daily').filter(obj => obj.st_id == st_id).filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(Number(n.transport_money) + Number(n.total_transport)), 0),

                total_cabina_dollar: this.list.filter(item => item.salary_type == 'Monthly').filter(obj => obj.st_id == st_id).filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(n.cabina_money), 0),
                total_cabina_dinar: this.list.filter(item => item.salary_type == 'Daily').filter(obj => obj.st_id == st_id).filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(n.cabina_money), 0),

                total_expense_dollar: this.list.filter(item => item.salary_type == 'Monthly').filter(obj => obj.st_id == st_id).filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(Number(n.expense_money) + Number(n.total_expense) + Number(n.expense_by_accomodation)), 0),
                total_expense_dinar: this.list.filter(item => item.salary_type == 'Daily').filter(obj => obj.st_id == st_id).filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(Number(n.expense_money) + Number(n.total_expense) + Number(n.expense_by_accomodation)), 0),

                total_fine_dollar: this.list.filter(item => item.salary_type == 'Monthly').filter(obj => obj.st_id == st_id).filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number((n.fine_money - n.total_fine) + n.fine_by_accomodation), 0),
                total_fine_dinar: this.list.filter(item => item.salary_type == 'Daily').filter(obj => obj.st_id == st_id).filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number((n.fine_money - n.total_fine) + n.fine_by_accomodation), 0),

                total_loan_dollar: this.list.filter(item => item.salary_type == 'Monthly').filter(obj => obj.st_id == st_id).filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(Number(n.loan_money) + Number(n.total_loan) + Number(n.loan_by_accomodation)), 0),
                total_loan_dinar: this.list.filter(item => item.salary_type == 'Daily').filter(obj => obj.st_id == st_id).filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(Number(n.loan_money) + Number(n.total_loan) + Number(n.loan_by_accomodation)), 0),

                total_accomodation_dollar: this.list.filter(item => item.salary_type == 'Monthly').filter(obj => obj.st_id == st_id).filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(Number(n.accomodation_money) + Number(n.total_accomodation) + Number(n.accomodation_by_accomodation)), 0),
                total_accomodation_dinar: this.list.filter(item => item.salary_type == 'Daily').filter(obj => obj.st_id == st_id).filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(Number(n.accomodation_money) + Number(n.total_accomodation) + Number(n.accomodation_by_accomodation)), 0),

                total_salary_per_month_dollar: this.list.filter(item => item.salary_type == 'Monthly').filter(obj => obj.st_id == st_id).filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(n.other_expense), 0),
                total_salary_per_month_dinar: this.list.filter(item => item.salary_type == 'Daily').filter(obj => obj.st_id == st_id).filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(n.other_expense), 0),

                total_other_minus_dollar: this.list.filter(item => item.salary_type == 'Monthly').filter(obj => obj.st_id == st_id).filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(n.other_minus), 0),
                total_other_minus_dinar: this.list.filter(item => item.salary_type == 'Daily').filter(obj => obj.st_id == st_id).filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(n.other_minus), 0),

                sub_total_dollar_dollar: this.list.filter(item => item.salary_type == 'Monthly').filter(obj => obj.st_id == st_id).filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + 
                    Number((this.getSuitableSalary(n) * n.count_present  + Number(((n.monthly_salary / 26) / 8 ) * n.total_hour) +   Number(n.food_money) + Number(n.transport_money) + Number(n.total_expense) + Number(n.expense_by_accomodation) + Number(n.fine_by_accomodation) + Number(n.total_transport) + Number(n.total_food) + Number(n.total_loan) + Number(n.total_accomodation) + Number(n.loan_by_accomodation) + Number(n.accomodation_by_accomodation) + Number(n.cabina_money) + Number(n.expense_money) + Number(n.fine_money) - Number(n.total_fine) + Number(n.loan_money) + Number(n.accomodation_money) + Number(n.other_expense) + Number(n.other_minus)).toFixed(2))
                , 0),
                sub_total_dollar_dinar: this.list.filter(item => item.salary_type == 'Monthly').filter(obj => obj.st_id == st_id).filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + 
                    Math.round(Number(((this.getSuitableSalary(n) * n.count_present) + Number(((n.monthly_salary / 26) / 8 ) * n.total_hour) +   Number(n.food_money) + Number(n.transport_money) + Number(n.total_expense) + Number(n.expense_by_accomodation) + Number(n.fine_by_accomodation) + Number(n.total_transport) + Number(n.total_food) + Number(n.total_loan) + Number(n.total_accomodation) + Number(n.loan_by_accomodation) + Number(n.accomodation_by_accomodation) + Number(n.cabina_money) + Number(n.expense_money) + Number(n.fine_money) - Number(n.total_fine) + Number(n.loan_money) + Number(n.accomodation_money) + Number(n.other_expense) + Number(n.other_minus)) * this.dollar_price))
                , 0),

                sub_total_dinar_dollar: this.list.filter(item => item.salary_type == 'Daily').filter(obj => obj.st_id == st_id).filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + 
                    Math.round(((n.daily_salary * n.count_present)  + ((n.daily_salary / 8 ) * n.total_hour)  + (n.food_money) - n.total_fine + n.transport_money + Number(n.total_expense) + Number(n.expense_by_accomodation) + Number(n.fine_by_accomodation) + Number(n.total_transport) + Number(n.total_food) + Number(n.total_loan) + Number(n.total_accomodation) + Number(n.loan_by_accomodation) + Number(n.accomodation_by_accomodation) + n.cabina_money + n.expense_money + n.fine_money + n.loan_money + n.accomodation_money + n.other_expense + n.other_minus)/ this.dollar_price)
                , 0),
                
                sub_total_dinar_dinar: this.list.filter(item => item.salary_type == 'Daily').filter(obj => obj.st_id == st_id).filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + 
                    Math.round((Math.round(n.daily_salary * n.count_present)  + Math.round((n.daily_salary / 8 ) * n.total_hour)  + (n.food_money) - n.total_fine + n.transport_money + Number(n.total_expense) + Number(n.fine_by_accomodation) + Number(n.expense_by_accomodation) + Number(n.total_transport) + Number(n.total_food) + Number(n.total_loan) + Number(n.total_accomodation) + Number(n.loan_by_accomodation) + Number(n.accomodation_by_accomodation) + n.cabina_money + n.expense_money + n.fine_money + n.loan_money + n.accomodation_money + n.other_expense + n.other_minus) )
                , 0),

            }
            return totals_obj;
        }
    },
    computed: {
        totals() {
            let obj = {
                total_salary_dollar: this.list.filter(item => item.salary_type == 'Monthly').filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + (this.getSuitableSalary(n) * n.count_present), 0),
                total_salary_dinar: this.list.filter(item => item.salary_type == 'Daily').filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + (this.getSuitableSalary(n) * n.count_present), 0),

                total_overtime_dollar: this.list.filter(item => item.salary_type == 'Monthly').filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number((((n.monthly_salary/26) / 8 ) * n.total_hour).toFixed(2)), 0),
                total_overtime_dinar: this.list.filter(item => item.salary_type == 'Daily').filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(((n.daily_salary / 8 ) * n.total_hour).toFixed(2)), 0),

                total_food_dollar: this.list.filter(item => item.salary_type == 'Monthly').filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(Number(n.food_money) + Number(n.total_food)), 0),
                total_food_dinar: this.list.filter(item => item.salary_type == 'Daily').filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(Number(n.food_money) + Number(n.total_food)), 0),

                total_transport_dollar: this.list.filter(item => item.salary_type == 'Monthly').filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(Number(n.transport_money) + Number(n.total_transport)), 0),
                total_transport_dinar: this.list.filter(item => item.salary_type == 'Daily').filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(Number(n.transport_money) + Number(n.total_transport)), 0),

                total_cabina_dollar: this.list.filter(item => item.salary_type == 'Monthly').filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(n.cabina_money), 0),
                total_cabina_dinar: this.list.filter(item => item.salary_type == 'Daily').filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(n.cabina_money), 0),

                total_expense_dollar: this.list.filter(item => item.salary_type == 'Monthly').filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(Number(n.expense_money) + Number(n.total_expense) + Number(n.expense_by_accomodation)), 0),
                total_expense_dinar: this.list.filter(item => item.salary_type == 'Daily').filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(Number(n.expense_money) + Number(n.total_expense) + Number(n.expense_by_accomodation)), 0),

                total_fine_dollar: this.list.filter(item => item.salary_type == 'Monthly').filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number((n.fine_money - n.total_fine) + n.fine_by_accomodation), 0),
                total_fine_dinar: this.list.filter(item => item.salary_type == 'Daily').filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number((n.fine_money - n.total_fine) + n.fine_by_accomodation), 0),

                total_loan_dollar: this.list.filter(item => item.salary_type == 'Monthly').filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(Number(n.loan_money) + Number(n.total_loan) + Number(n.loan_by_accomodation)), 0),
                total_loan_dinar: this.list.filter(item => item.salary_type == 'Daily').filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(Number(n.loan_money) + Number(n.total_loan) + Number(n.loan_by_accomodation)), 0),

                total_accomodation_dollar: this.list.filter(item => item.salary_type == 'Monthly').filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(Number(n.accomodation_money) + Number(n.total_accomodation) + Number(n.accomodation_by_accomodation)), 0),
                total_accomodation_dinar: this.list.filter(item => item.salary_type == 'Daily').filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(Number(n.accomodation_money) + Number(n.total_accomodation) + Number(n.accomodation_by_accomodation)), 0),

                total_salary_per_month_dollar: this.list.filter(item => item.salary_type == 'Monthly').filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(n.other_expense), 0),
                total_salary_per_month_dinar: this.list.filter(item => item.salary_type == 'Daily').filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(n.other_expense), 0),

                total_other_minus_dollar: this.list.filter(item => item.salary_type == 'Monthly').filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(n.other_minus), 0),
                total_other_minus_dinar: this.list.filter(item => item.salary_type == 'Daily').filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + Number(n.other_minus), 0),

                sub_total_dollar_dollar: this.list.filter(item => item.salary_type == 'Monthly').filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + 
                    Number((this.getSuitableSalary(n) * n.count_present  + Number(((n.monthly_salary / 26) / 8 ) * n.total_hour) +   Number(n.food_money) + Number(n.transport_money) + Number(n.total_expense) + Number(n.expense_by_accomodation) + Number(n.fine_by_accomodation) + Number(n.total_transport) + Number(n.total_food) + Number(n.total_loan) + Number(n.total_accomodation) + Number(n.loan_by_accomodation) + Number(n.accomodation_by_accomodation) + Number(n.cabina_money) + Number(n.expense_money) + Number(n.fine_money) - Number(n.total_fine) + Number(n.loan_money) + Number(n.accomodation_money) + Number(n.other_expense) + Number(n.other_minus)).toFixed(2))
                , 0),
                sub_total_dollar_dinar: this.list.filter(item => item.salary_type == 'Monthly').filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + 
                    Math.round(Number(((this.getSuitableSalary(n) * n.count_present) + Number(((n.monthly_salary / 26) / 8 ) * n.total_hour) +   Number(n.food_money) + Number(n.transport_money) + Number(n.total_expense) + Number(n.expense_by_accomodation) + Number(n.fine_by_accomodation) + Number(n.total_transport) + Number(n.total_food) + Number(n.total_loan) + Number(n.total_accomodation) + Number(n.loan_by_accomodation) + Number(n.accomodation_by_accomodation) + Number(n.cabina_money) + Number(n.expense_money) + Number(n.fine_money) - Number(n.total_fine) + Number(n.loan_money) + Number(n.accomodation_money) + Number(n.other_expense) + Number(n.other_minus)) * this.dollar_price))
                , 0),

                sub_total_dinar_dollar: this.list.filter(item => item.salary_type == 'Daily').filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + 
                    Math.round(((n.daily_salary * n.count_present)  + ((n.daily_salary / 8 ) * n.total_hour)  + (n.food_money) - n.total_fine + n.transport_money + Number(n.total_expense) + Number(n.expense_by_accomodation) + Number(n.fine_by_accomodation) + Number(n.total_transport) + Number(n.total_food) + Number(n.total_loan) + Number(n.total_accomodation) + Number(n.loan_by_accomodation) + Number(n.accomodation_by_accomodation) + n.cabina_money + n.expense_money + n.fine_money + n.loan_money + n.accomodation_money + n.other_expense + n.other_minus)/ this.dollar_price)
                , 0),
                
                sub_total_dinar_dinar: this.list.filter(item => item.salary_type == 'Daily').filter(obj => !this.zeros.find(z => z.emp_id == obj.emp_id && z.st_id == obj.st_id)).reduce((acc, n) => acc + 
                    Math.round((Math.round(n.daily_salary * n.count_present)  + Math.round((n.daily_salary / 8 ) * n.total_hour)  + (n.food_money) - n.total_fine + n.transport_money + Number(n.total_expense) + Number(n.expense_by_accomodation) + Number(n.fine_by_accomodation) + Number(n.total_transport) + Number(n.total_food) + Number(n.total_loan) + Number(n.total_accomodation) + Number(n.loan_by_accomodation) + Number(n.accomodation_by_accomodation) + n.cabina_money + n.expense_money + n.fine_money + n.loan_money + n.accomodation_money + n.other_expense + n.other_minus) )
                , 0),

            }
            return obj;
        }
    }
}
</script>


<style scoped>
    .rtl {
        text-align: right !important;
    }
    tbody td {
        padding: .5rem;
    }
    .para {
        background-color: rgb(81, 224, 81) !important;
        color: black !important;
    }
    .para-total {
        background-color: rgb(68, 171, 231) !important;
        color: black !important;
    }
    th, td {
        font-size: 11pt;
        padding-inline: 6px;
        /* border: 1px solid black !important; */
    }
    th, td:not(.text-danger) {
        color: rgb(17, 17, 17);
    }
    @media print {
        .no-print, #nav {
            display: none;
        }
        *:not(.para, .para-total, .expired-passport-date) {
            background-color: white ;
            
        }
        th, td {
            padding-inline: 6px;
        }
        .para {
            background-color: rgb(81, 224, 81) !important;
            color: black !important;
        }
        .para-total {
            background-color: rgb(68, 171, 231) !important;
            color: black !important;
        }
        table {
            font-size: 9pt !important;
        }
        @page {
            size: landscape;
        }
    }
</style>
